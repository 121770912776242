<template>
    <div>
        <header-portrate />
    <div class="pa-2 m-2">
        
        <bread-crumbs :items="items"></bread-crumbs>
        <tabs-comp :tabs="tabs" />
        <v-simple-table class="mt-4">
            <thead>
                <tr>
                    <th class="text-center">{{lang.date}}</th>
                    <th class="text-center">{{lang.purchase}}</th>
                    <th class="text-center">{{lang.general_expenses}}</th>
                    <th class="text-center" v-if="$store.state.licenseType.cars">{{lang.cards_expenses}}</th>
                    <th class="text-center">{{lang.invoices_expenses}}</th>
                    <!-- <th class="text-center">{{lang.salaries}}</th> -->
                    <th class="text-center">{{lang.periodically}}</th>
                    <th class="text-center">{{lang.totals}}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item,index) in expensrows" :key="index">
                    <td class="text-center">{{item.date}}</td>
                    <td class="text-center">{{item.bill_total}}</td>
                    <td class="text-center">{{item.gexp_total}}</td>
                    <td class="text-center" v-if="$store.state.licenseType.cars">{{item.cexp_total}}</td>
                    <td class="text-center">{{item.iexp_total}}</td>
                    <!-- <td class="text-center">{{item.salary_exp}}</td> -->
                    <td class="text-center">{{item.period_exp}}</td>
                    <td class="text-center">{{item.total}}</td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <th class="text-center">{{lang.totals}}</th>
                    <th class="text-center">{{b_total}}</th>
                    <th class="text-center">{{g_total}}</th>
                    <th class="text-center" v-if="$store.state.licenseType.cars">{{c_total}}</th>
                    <th class="text-center">{{i_total}}</th>
                    <!-- <th class="text-center">{{s_total}}</th> -->
                    <th class="text-center">{{p_total}}</th>
                    <th class="text-center">{{f_total}}</th>
                </tr>
            </tfoot>
        </v-simple-table>
    </div>
    <Footer />
    </div>
</template>

<script>
import breadCrumbs from '@/components/breadCrumbs.vue'
import TabsComp from '@/components/tabsComp.vue'
import axios from 'axios';
import Footer from '@/components/Footer.vue'
import HeaderPortrate from '@/components/Header-Portrate.vue';
export default {
    components: { breadCrumbs, TabsComp, Footer, HeaderPortrate },
    data() {
        return {
            expensrows: [],
            sdate: '',
            edate: '',
        }
    },
    created() {
        this.getExpensesCall();
    },
    methods: {
        getExpensesCall(){
            const post = new FormData();
            post.append("type" , "getExpenseSummary");
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append("data[sdate]",this.sdate);
            post.append("data[edate]",this.edate);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    ///this.exprows = res.results.data.results;
                    // console.log(res);
                    this.expensrows = res.results.data;
                }
            )
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        tabs: function(){
            let t = [
                {
                    index:0,name:this.lang.chart_of_account,href:'/finance/coa',class: 'mytab3',smclass:'font-small', full: true
                },
                {
                    index:0,name:this.lang.finance_jvs,href:'/finance/jvs',class: 'mytab3',smclass:'font-small', full: true
                },
                {
                    index:0,name:'أرصدة افتتاحية',href:'/finance/fient',class: 'mytab2',smclass:'font-small', full: true
                },
                // {
                //     index:1,name:this.lang.exchange_voucher,href:'/finance/outreceipt',class: 'mytab5',smclass:'font-small'
                // },
                {
                    index:0,name:this.lang.paytypes,href:'/finance/paytypes',class: 'mytab3',smclass:'font-small', full: false
                },
                {
                    index:0,name:this.lang.fixed_assets,href:'/finance/fixed-assets',class: 'mytab3',smclass:'font-small', full: true
                },
                {
                    index:0,name:this.lang.definition,href:'/finance/definition',class: 'mytab3',smclass:'font-small', full: true
                },
                {
                    index:0,name:this.lang.expenses_summary,href:'/expenses/summary',class: 'mytab',smclass:'font-small', full: false
                },
                {
                    index:1,name:this.lang.general_expenses,href:'/expenses/general',class: 'mytab2',smclass:'font-small', full: false
                },
                {
                    index:1,name:this.lang.exp_management,href:'/expenses/management',class: 'mytab2',smclass:'font-small', full: false
                },
                {
                    index:1,name:this.lang.recurring_expensess,href:'/expenses/recurring',class: 'mytab3',smclass:'font-small', full: true
                },
                
            ]
          let x = [];
          for(let i=0;i<t.length;i++){
            if(t[i].full === false && this.$store.state.licenseType.onlySalesPurchase){
              x.push(t[i])
            }else if(!this.$store.state.licenseType.onlySalesPurchase){
              x.push(t[i])
            }
          }
          return x;
        },
        items: function() {
            return {
                    text: this.lang.expenses_summary,
                    disabled: true,
                    to: '/expenses/summary',
                }
        },
        b_total: function(){
            let t = 0;
            for(let i = 0;i<this.expensrows.length;i++){
                t = parseFloat(t) + parseFloat(this.expensrows[i].bill_total);
            }
            return this.$RoundNum(t);
        },
        g_total: function(){
            let t = 0;
            for(let i = 0;i<this.expensrows.length;i++){
                t = parseFloat(t) + parseFloat(this.expensrows[i].gexp_total);
            }
            return this.$RoundNum(t);
        },
        c_total: function(){
            let t = 0;
            for(let i = 0;i<this.expensrows.length;i++){
                t = parseFloat(t) + parseFloat(this.expensrows[i].cexp_total);
            }
            return this.$RoundNum(t);
        },
        i_total: function(){
            let t = 0;
            for(let i = 0;i<this.expensrows.length;i++){
                t = parseFloat(t) + parseFloat(this.expensrows[i].iexp_total);
            }
            return this.$RoundNum(t);
        },
        s_total: function(){
            let t = 0;
            for(let i = 0;i<this.expensrows.length;i++){
                t = parseFloat(t) + parseFloat(this.expensrows[i].salary_exp);
            }
            return this.$RoundNum(t);
        },
        p_total: function(){
            let t = 0;
            for(let i = 0;i<this.expensrows.length;i++){
                t = parseFloat(t) + parseFloat(this.expensrows[i].period_exp);
            }
            return this.$RoundNum(t);
        },
        f_total: function(){
            let t = 0;
            t = parseFloat(t) + parseFloat(this.b_total);
            t = parseFloat(t) + parseFloat(this.g_total);
            t = parseFloat(t) + parseFloat(this.c_total);
            t = parseFloat(t) + parseFloat(this.i_total);
            t = parseFloat(t) + parseFloat(this.s_total);
            t = parseFloat(t) + parseFloat(this.p_total);
            
            return this.$RoundNum(t);
        },
    },
}
</script>